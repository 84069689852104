@tailwind base;
@tailwind components;
@tailwind utilities;

/* Start Global Rules */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* End Global Rules */
.landing-bg {
  height: calc(100vh - 72);
}

@layer components {
  .wrapper {
    @apply w-4/5 lg:w-[996px] mx-auto;
  }
}
